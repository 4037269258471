import React from "react";
import { Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components";

const Blogstyle = styled.div`
  h4 {
    margin-top: 15px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 24px;
    background: linear-gradient(180deg,#FF0000 80%,rgba(255,0,0,0.3) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    color: #2c2c2c;
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 3em;
  }
`

const PostCasinoPage = ( props ) => {
    const { allWpPost } = useStaticQuery(
        graphql`
        {
            allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "คาสิโน"}}}}}) {
              edges {
                node {
                  id
                  slug
                  status
                  title
                  content
                  excerpt
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `
    );

    const posts = allWpPost.edges.filter(({ node }) => {
        return node.slug !== props.slug;
    });

    return posts ? (
        <div>
            <Row>
                {posts.map(({node}) => {
                    const title = node.title || node.slug
                    const getphoto = node.featuredImage === null 
                        ? null 
                        : node.featuredImage.node.localFile.childImageSharp
                    const image = getImage(getphoto)
                    const link = node.slug
                    const excerpt = node.excerpt
                        return <Col md={4} key={node.slug}>
                            <Link to={`/${link}/`}>
                                <Blogstyle>
                                    <GatsbyImage image={image} />
                                    <h4>{title}</h4>
                                    <div dangerouslySetInnerHTML={{__html: excerpt}} />
                                </Blogstyle>
                            </Link>
                        </Col>
                })}
            </Row>
        </div>

    ) : null;

}

export default PostCasinoPage